import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { Hero } from "../../containers"
import { BreadCrumb, TitleV2 } from "../../components"
import BusinessOPerationsCrumbs from "../../constants/BreadCrumbs/BusinessOperationsCrumbs"

const OperationsPage = ({ data }) => {
  const image = getImage(
    data.strapiOperations.image.localFile.childImageSharp.gatsbyImageData
  )
  return (
    <section>
      <Hero image={image} title="Business Operations" />
      <BreadCrumb crumbs={BusinessOPerationsCrumbs} />
      <div className="uk-container">
        <div className="uk-margin-small-top">
          <TitleV2 title={data.strapiOperations.title} />
        </div>

        <h3>Business Modles</h3>
        <h3>Build vs Buy</h3>
        <h3>Respect Your Budget</h3>
        <h3>Vision & Strategy</h3>
        <h3>Tactics & Execution</h3>
        <h3>Results</h3>
        <br />
        <br />
      </div>
    </section>
  )
}

export default OperationsPage

export const query = graphql`
  {
    strapiOperations {
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(formats: AUTO, placeholder: BLURRED)
          }
        }
      }
      title
    }
  }
`
